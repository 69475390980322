@import "variables";

.Section {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  background-size: cover;
  background-position: center center;

  &.full-height {
    min-height: 100vh;
  }

  &.dark-bg {
    color: white;
  }

  &.dark-bg:before {
    content: " ";

    background-color: rgba(#000000, 0.55);
    //background-color: rgba($sprint-color, 0.7);

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100vh;
  }

  .inner {
    width: 100%;
    z-index: 1;

    @include lg() {
      width: $lg-width;
    }
  }
}
