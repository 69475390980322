@import "variables";

.App {
  text-align: center;
}

#canpack {
  #hero {
    .timer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .countdown-part {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        min-width: 6rem;
        margin: 1rem 0;

        @include sm() {
          & {
            min-width: 8rem;
          }
        }

        .countdown-value {
          font-size: 4rem;
          line-height: 4rem;
        }
      }
    }

    .logo {
      img {
        box-sizing: border-box;
        padding: 0 1rem;
        max-width: 100%;
      }

      &.canpack {
        img {
          width: 40%;
        }
      }
    }

    .for {
      margin: 0 0 1.5rem;
    }

    .countdown {
      margin: 6rem 0 4rem;

      h1 {
        font-size: 4rem;
        margin: 1rem 0;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        img {
          height: 4rem;
          margin: 1rem;
        }
      }

      p {
        font-size: 1.5rem;
        margin: 0;

        &.open-sprint {
          margin-bottom: 2rem;
        }
      }
    }

    .Button {
      padding: 1.25rem 3rem;
      font-size: 1.5rem;
      line-height: initial;

      &.large {
        padding: 1.5rem 4rem;
        font-size: 2.75rem;
        line-height: initial;
      }
    }
  }
}

a {
  color: shade($sprint-color, 50%);
  transition: color ease-in-out 200ms;
  text-decoration: none;

  &:hover,
  &:active {
    color: shade($sprint-color, 30%);
  }
}

.icons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: -2rem;

  .size-half {
    width: 100%;
  }

  .IconBox {
    margin-bottom: 2rem;
  }

  @include sm() {
    .size-half {
      width: 45%;
    }
  }
}

#who {
  .card {
    padding: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .content {
      padding: 3rem 2rem;
      margin-left: 0;
      background: #ffffffde;

      .title {
        margin-bottom: 0;
      }

      .subtitle {
        margin-top: 0;
      }

      .signature {
        display: flex;
        justify-content: flex-end;
        text-align: right;

        img,
        h2,
        p {
          margin: 0;
        }

        img {
          margin-left: 1rem;
          width: 6rem;
          border-radius: 50%;
        }

        .text {
          display: flex;
          flex-direction: column;
          justify-content: center;

          h2 {
            margin-bottom: 0.25rem;
          }

          p {
            line-height: 1.25em;
          }

          .position {
            font-size: 0.8em;
          }
        }
      }

      @include md() {
        & {
          margin-left: 50%;
        }
      }
    }
  }
}

.menu {
  position: fixed;
  width: 100%;
  height: 40px;

  top: 0;
  left: 0;

  z-index: 10;

  box-sizing: border-box;
  padding: 4px 4px;

  background-color: $sprint-color;

  display: none;

  @include sm() {
    display: flex;

    justify-content: space-between;
    align-items: center;
  }

  @include lg() {
    padding: 4px 20px;
  }

  .logo {
    height: 100%;

    box-sizing: border-box;
    padding: 2px 0;

    @include lg() {
      padding: 0;
    }

    a {
      display: flex;
      height: 100%;
      width: 100%;

      img {
        height: 100%;
        width: auto;
      }
    }
  }

  .links {
    a {
      margin-right: 1rem;
      color: tint($sprint-color, 80);
      font-weight: 700;

      &:hover,
      &:active {
        color: white;
      }
    }
  }
}

section {
  .inner {
    .card {
      padding: 3rem 2rem;
      margin: 2rem 0;
      box-sizing: border-box;

      background-color: white;

      & > *:first-child {
        margin-top: 0;
      }

      & > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.Button.accent {
  background-color: shade($complementary-color, 10);

  &:hover,
  &:active {
    background-color: $complementary-color;
  }
}

.Section {
  &:nth-child(4n + 2) {
    background-color: $sprint-color;
  }

  &:nth-child(4n + 3) {
    background-color: $complementary-color;
  }

  &:nth-child(4n) {
    background-color: $support-color-1;
  }

  &:nth-child(4n + 1) {
    background-color: $support-color-2;
  }

  .card {
    .actions {
      margin-bottom: -1rem !important;

      display: flex;
      align-items: center;
      justify-content: center;

      .Button {
        margin-right: 1rem;
        margin-bottom: 1rem;

        &:last-child {
          margin-right: 0;
        }

        &.return {
          background-color: #333;

          &:hover {
            background-color: #444;
          }
        }
      }
    }
  }
}

#hero {
  .title {
    font-size: 3rem;
    margin: 0 0 1rem;

    font-weight: 900;

    @include lg() {
      font-size: 5rem;
    }
  }

  .subtitle {
    font-size: 1.5rem;
    margin: 0 0 3rem;

    @include lg() {
      font-size: 2rem;
    }
  }
}

#about-sprintech {
  .inner {
    .icons {
      display: flex;
      flex-direction: column;

      margin-bottom: 2rem;

      @include sm() {
        flex-direction: row;
      }

      .IconBox {
        width: 100%;
        justify-content: flex-start;
      }
    }

    p {
      font-size: 1.25rem;
    }
  }
}

#case-studies {
  .icons {
    width: 100%;
    margin-bottom: 2rem;

    @include md() {
      width: 80%;
      margin: auto auto 2rem;
    }

    .case-study-icon-box {
      transition: background-color ease-in-out 200ms;
      background-color: #e5e5e5;
      padding: 1.5rem;

      margin-bottom: 0.5rem;

      &:hover {
        background-color: #eeeeee;
      }

      .icon {
        width: 6rem;
      }
    }
  }
}

.case-study {
  .info {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100%;

    .count {
      color: #444;
      margin: 0;
    }

    .title {
      margin: 0;
    }
  }
}

.case-study-details {
  .IconBox.case-study-details-icon-box {
    padding: 2rem;

    &:nth-child(even) {
      background-color: #eeeeee;
    }

    .icon {
      margin-right: 1rem;
      width: 4rem;
    }
  }
}

#how-we-work,
#pricing {
  .IconBox {
    &:nth-child(even) {
      background-color: #eeeeee;
    }

    margin-bottom: 2rem;

    .icon {
      width: 6rem;
    }

    .content {
      font-size: 1.25rem;
    }
  }

  .cooperation-details-icon-box {
    .icon {
      margin-right: 1rem;

      @include sm() {
        margin-right: 2rem;
      }
    }

    .content {
      display: flex;
      align-items: center;

      .step {
        font-family: $header-font;
        font-size: 3rem;

        margin-right: 1rem;

        @include sm() {
          margin-right: 2rem;
        }
      }

      .description {
        width: 100%;
      }
    }
  }
}

#contact {
  h3 {
    margin: 0;
    font-weight: 400;
  }

  .phone,
  .mail {
    margin: 0;

    line-height: 3rem;
    font-size: 2rem;
    font-weight: 700;
  }
}

footer {
  padding: 2rem 0;
  color: #ddd;
  background-color: #222;

  p {
    margin: 0;
    padding-bottom: 0.5rem;

    &:last-child {
      padding-bottom: 0;
    }
  }
}
